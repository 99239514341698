import React from "react";
<section id="footer">
</section>
const Footer = () => {
  
  return (
    <footer
      className="text-white py-8 mt-24"
      style={{ borderTop: "1px solid #333" }}
    >
      
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4">
        <div className="text-center md:text-left mb-4 md:mb-0">
          
          <p>&copy; 2024 Carlos Georgiev</p>
        </div>
        <div className="flex items-center">
          <a
            href="https://www.linkedin.com/in/carlos-georgiev/"
            className="text-white hover:text-gray-400 mr-4 transition duration-300"
          >
            LinkedIn
          </a>
          <a
            href="https://www.facebook.com/carlosgeorgiev"
            className="text-white hover:text-gray-400 mr-4 transition duration-300"
          >
            Facebook
          </a>
          <a
            href="https://www.instagram.com/carlos_georgiev/"
            className="text-white hover:text-gray-400 transition duration-300"
          >
            Instagram
          </a>
          
        </div>
      </div>
    </footer>
  );
};

export default Footer;

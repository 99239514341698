import React from "react";
import CalendlyInlineWidget from "./CalendlyIntegration";

const HireMeCalendarSection = () => {
  return (
    <div className=" py-16" id="contact">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-indigo-400 font-semibold tracking-wide uppercase">
            Services
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
            Schedule a Meeting
          </p>
        </div>
        <div className="mt-10">{<CalendlyInlineWidget />}</div>
      </div>
    </div>
  );
};
export default HireMeCalendarSection;

import React, { useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { FaPlus, FaMinus } from "react-icons/fa";

const ContactForm = () => {
  const { handleSubmit, register, control, formState: { errors } } = useForm();
  const [setFormData] = useState({});
  const [accordionOpen, setAccordionOpen] = useState(-1);

  const toggleAccordion = (index) => {
    setAccordionOpen(index === accordionOpen ? -1 : index);
  };

  const onSubmit = async (data) => {
    const formSpreeEndpoint = 'https://formspree.io/f/meqyjvra';
  
    try {
      const response = await fetch(formSpreeEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        console.log('Form submitted successfully');
        setFormData({});
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  
  const accordionItems = [
    {
      title: "Hardware services",
      content:
        "Installation & Configuration: smooth setup of personal computers, workstations, cloud environments and network devices.\n\n" +
        "Upgrades & Repairs: comprehensive services to boost performance and extend lifespan.\n\n" +
        "Troubleshooting & Diagnostics: swift resolution of technical issues, minimizing downtime.\n\n" +
        "Maintenance & Optimization: proactive upkeep to enhance performance, security, and longevity.",
    },
    {
      title: "Software Services",
      content:
        "Software Maintenance and Support: To ensure its long-term success and reliability, it's essential to invest in ongoing maintenance and support. I offer proactive monitoring, regular updates, and timely troubleshooting to keep your software running smoothly and securely. Whether you encounter technical issues, need to implement new features, or simply want to optimize performance.\n\n" +
        "Web & Mobile Development: Dynamic websites and user-friendly mobile apps.\n\n" +
        "Integration Solutions: Enhanced efficiency through seamless software integration.",
    },
    {
      title: "Consultant Services",
      content: "IT Strategy Development,\n" +
      "Technology assessment and advisory,\n" +
      "Digital transformation planning,\n" +
      "Cloud computing consultation,\n" +
      "Cybersecurity consulting,\n" +
      "Training and capacity building.",
    },
    {
      title: "More information",
      content:
        "I encourage you to contact me, as I have acquired more skills over time and provide multiple services that I may not have mentioned here.",
    },
  ];

  return (
    <div className="flex justify-center">
      <div className="lg:w-3/4 p-8 border rounded-lg bg-gray-800 text-white">
        <h2 className="text-3xl font-semibold mb-4">Contact Us</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <label htmlFor="name" className="block mb-1">Name</label>
          <input {...register('name', { required: 'Name is required' })} className="w-full border border-gray-300 text-gray-800 rounded px-4 py-2 focus:outline-none focus:border-blue-400" />
          {errors.name && <p className="error">{errors.name.message}</p>}

          <label htmlFor="email" className="block mb-1">Email</label>
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
            render={({ field }) => <input {...field} className="w-full border border-gray-100 text-gray-800 rounded px-4 py-2 focus:outline-none focus:border-blue-400" />}
          />
          {errors.email && <p className="error">{errors.email.message}</p>}

          <label htmlFor="message" className="block mb-1">Message</label>
          <textarea {...register('message', { required: 'Message is required' })} className="w-full border border-gray-300 text-gray-800 rounded px-4 py-2 focus:outline-none focus:border-blue-400" />
          {errors.message && <p className="error">{errors.message.message}</p>}

          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">Submit</button>
        </form>
        
        <div className="mt-8">
          {accordionItems.map((item, index) => (
            <div key={index} className="mb-4">
              <button
                className="flex justify-between items-center w-full bg-gray-700 text-white px-4 py-2 rounded focus:outline-none"
                onClick={() => toggleAccordion(index)}
              >
                <span>{item.title}</span>
                {accordionOpen === index ? <FaMinus /> : <FaPlus />}
              </button>
              {accordionOpen === index && (
                <div className="bg-gray-900 text-white px-4 py-2 mt-2 rounded">
                  {item.content}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

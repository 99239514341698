import React, { useEffect, useRef } from "react";
import me from "./croped.png";
import { gsap, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import 'tailwindcss/tailwind.css';

gsap.registerPlugin(ScrollTrigger);

const useIntersectionObserver = (ref, options, animation) => {
  useEffect(() => {
    const currentRef = ref.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animation(entry.target);
        }
      });
    }, options);

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref, options, animation]); 
};

const CvButtons = () => {
  const buttonRef = useRef(null);

  const animateButton = (target) => {
    gsap.fromTo(
      target,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, ease: "power1.inOut", duration: 0.5 }
    );
  };

  useIntersectionObserver(buttonRef, { threshold: 0.1 }, animateButton);

  const downloadCv = () => {
    const cvFilePath = process.env.PUBLIC_URL + '/resume.pdf';
    window.open(cvFilePath, '_blank');
  };

  return (
    <div className="container py-5 px-5 mx-0 min-w-full flex flex-col items-center cv-buttons" ref={buttonRef}>
      <button
        onClick={downloadCv}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
      >
        View CV
      </button>
    </div>
  );
};

const AboutMeSection = () => {
  const sectionRef = useRef(null);

  const animateSection = (target) => {
    gsap.fromTo(
      target.querySelectorAll('.about-text, h2, img'),
      { opacity: 0, y: 20 },
      {
        opacity: 1, y: 0, ease: Power1.easeInOut, duration: 1, stagger: 0.1,
        scrollTrigger: {
          trigger: target,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "restart pause resume pause"
        }
      }
    );
  };

  useIntersectionObserver(sectionRef, {}, animateSection);

  return (
    <div className="py-16" ref={sectionRef}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-indigo-400 font-semibold tracking-wide uppercase">
            About Me
          </h2>

          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl about-text">
            Who Am I?
          </p>

          <p className="font-arial text-center text-xxl text-white about-text">
            I'm an IT Specialist based in Bulgaria who can bring solutions remotely or on-site.
            For more contact - +359 98 898 4480 / 098 898 4480
          </p>
        </div>
        <div id="About"></div>
        <div className="mt-20 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="md:col-span-1">
          
            <img
              className="w-full object-cover rounded-lg shadow-lg"
              src={me}
              alt="Carlos"
            />
          </div>
          <div className="md:col-span-1">
            <section id="about">
            
              <p className="font-arial text-center text-xxl text-white about-text">
                Hello again! I specialize in system administration with programming and cybersecurity skills who desires to become better each day.
                I can talk/write all day about the technical skills that I have acquired over the years, but I think it would be easier if you just click the CV button.
                You can find more projects like this website in my social medias below.
              </p>
              
              <p className="font-arial text-center mt-4 text-xl text-white about-text">
                In my free time, I tend to play a lot of chess, which has developed my concentration, creativity, and critical thinking to a whole different level! Additionally, I enjoy traveling, driving cars, and playing games, all of which have contributed to shaping who I am today.
              </p>
              
              <p className="text-center mt-4 text-xl text-white about-text">
                Contact me and let's find a way to collaborate!
              </p>
            </section>
            <CvButtons />
          </div>
        </div>
      </div>
    </div>
  
  );
};

export default AboutMeSection;

import React, { useEffect } from 'react';

const CalendlyInlineWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/carlossproject/30min"
        style={{ position: 'relative', minWidth: '250px', height: '1000px' }}
      />
    </>
  );
};

export default CalendlyInlineWidget;
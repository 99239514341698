import "./App.css";
import AboutMeSection from "./Components/Aboutme/Aboutme.tsx";
import Footer from "./Components/Footer/Footer.tsx";
import ContactForm from "./Components/Form/Form.tsx";
import HeroSection from "./Components/Hero/Hero.tsx";
import HireMeCalendarSection from "./Components/Hireme/Hireme.tsx";
import ResponsiveAppBar from "./Components/Navbar/Navbar.tsx";
import SkillsSection from "./Components/Skills/Skills.tsx";

function App() {
  return (
    <div className="App">
      <ResponsiveAppBar />
      <HeroSection />
      <AboutMeSection />
      <SkillsSection />
      <HireMeCalendarSection />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;

import React, { useRef, useEffect } from "react";
import hero from "./hero.png";
const HeroSection = () => {
  const backgroundRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (backgroundRef.current) {
        const yPos = window.scrollY / 2;
        backgroundRef.current.style.transform = `translateY(${yPos}px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="relative overflow-hidden " id="about">
      <div className="absolute inset-0">
        <div
          className="absolute inset-0 bg-gray-400 mix-blend-multiply"
          aria-hidden="true"
        ></div>
        <img
          src={hero}
          alt="Carlos Georgiev"
          className="h-full w-[100%] m-[auto] objec-cover"
        />
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
          Hi , I am{" "}
          <span className="text-transparent bg-gradient-to-r from-cyan-500 to-blue-500 bg-clip-text">
            Carlos Georgiev
          </span>
        </h1>
        <p className="mt-6 max-w-3xl text-xl text-gray-300">
        Discover IT solutions with a personal touch. Let's innovate together!
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
